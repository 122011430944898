//load all images
const images = require.context('./images', true)

// load all styles
import './css/application';
import 'swiper/swiper.scss';


import Swiper, { Navigation } from 'swiper';
window.Swiper = Swiper;

// init global user javascript
import './js/global/index.js';
import './js/global/post.js';
