setTimeout(() => {
  console.log("Delayed for 4 second.");

  var VideoSwiper = new Swiper(".VideoSwiper", {
    slidesPerView: 1.2,
    spaceBetween: 10,
    // loop: true,
    pagination: {
      el: ".video-swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1.2,
        spaceBetween: 10,
      },
      480: {
        slidesPerView: 1.7,
        spaceBetween: 20,
      },

      640: {
        slidesPerView: 2.2,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
  });
  var ShortStoriesSwiper = new Swiper(".ShortStoriesSwiper", {
    slidesPerView: 1.2,
    spaceBetween: 10,
    // loop: true,
    pagination: {
      el: ".short-stories-swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.2,
        spaceBetween: 10,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1.5,
        spaceBetween: 20,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
  });

  var LateViewSwiper = new Swiper(".LateViewSwiper", {
    slidesPerView: 1.1,
    spaceBetween: 10,
    loop: true,
    pagination: {
      el: ".late-view-swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.1,
        spaceBetween: 10,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1.3,
        spaceBetween: 20,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 1.5,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1400: {
        slidesPerView: 2.5,
        spaceBetween: 20,
      },
    },
  });

  // Theme toggleSwitch
  // const toggleSwitch = document.querySelector("#toggleThemeButton");

  // function switchTheme(e) {
  //   // updateTheme();
  //   if (e.target.checked) {
  //     document.documentElement.setAttribute("data-theme", "dark");
  //     localStorage.setItem("data-theme", "dark");
  //   } else {
  //     document.documentElement.setAttribute("data-theme", "light");
  //     localStorage.setItem("data-theme", "light");
  //   }
  // }
  // toggleSwitch.addEventListener("change", switchTheme, false);

  // // Function updated theme by getting values from localStorage
  // function updateTheme() {
  //   var currentTheme = localStorage.getItem("data-theme");
  //   document.documentElement.setAttribute("data-theme", currentTheme);
  //   if (currentTheme === "dark") {
  //     toggleSwitch.checked = true;
  //   } else {
  //     toggleSwitch.checked = false;
  //   }
  // }
  // updateTheme();

  // ======================================
  // ============ Navbar ==================
  // ======================================

  const navBarToggleButton = document.querySelector("#navBarToggleButton");
  const navBarToggleBackDrop = document.querySelector(
    ".ts-navbar-mobile-backdrop"
  );
  const navBarMobileMenu = document.querySelector(".ts-navbar-mobile");

  function toggleNavBar() {
    navBarToggleButton.classList.toggle("active");
    navBarMobileMenu.classList.toggle("active");
    document.body.classList.toggle("overflow-hidden");
    // setTimeout(function () {
    //   navBarMobileMenu.classList.toggle("active-animation");
    // }, 4000); //Time before execution
    toggleSearchBar();
  }
  navBarToggleButton.addEventListener("click", toggleNavBar);
  navBarToggleBackDrop.addEventListener("click", toggleNavBar);

  // Search

  const searchBarToggle = document.querySelector(".ts-search-bar__btn-toggle");
  const searchBarToggleSearchGroup = document.querySelector(
    ".ts-search-bar__search"
  );

  function toggleSearchBar() {
    searchBarToggleSearchGroup.classList.toggle("active");
  }
  searchBarToggle.addEventListener("click", toggleSearchBar);
}, "1000");
