// Remove set time out function it's temporary only for my setup needed

setTimeout(() => {
  var swiperArticleGallery = new Swiper(".swiperArticleGallery", {
    slidesPerView: 2,
    spaceBetween: 6,
    loop: true,
    pagination: {
      el: ".swiper-pagination-swiperArticleGallery",
      clickable: true,
    },
    breakpoints: {
      400: {
        slidesPerView: 2.5,
        spaceBetween: 6,
      },

      992: {
        slidesPerView: 3,
        spaceBetween: 6,
      },
    },
  });
}, "1000");
